window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
    
    if (event.detail.screenName === 'fin') {
        console.log('heyflow screen view:', event.detail.screenName);
_tfa.push({notify: 'event', name: 'SubmitForm', id: 1455161});

    }
       
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);








});